import 'intersection-observer';
import $ from 'jquery';

export function navBarScrollHandler()
{

  const header = document.querySelector(".navbar"); //$("#navbar-id");
  const sectionOne = document.querySelector(".js-observe"); //$("#video-id");

  const sectionOneOptions = {
    rootMargin: "-225px 0px 0px 0px"
  };

  const sectionOneObserver = new IntersectionObserver(function(
    entries,
    sectionOneObserver
  ) {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        header.classList.add("nav-scrolled");
      } else {
        header.classList.remove("nav-scrolled");
      }
    });
  },
  sectionOneOptions);

  function clickHandler(event) {
    requestAnimationFrame(() => {
      if(!button.hasClass("collapsed")){
        header.classList.add("navbar-solid");
      }else{
        header.classList.remove("navbar-solid");
      }
    });
  }

  const button = $('nav.navbar-medicos button');
  button.on('click', clickHandler );

  sectionOneObserver.observe(sectionOne);

};

